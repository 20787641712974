export default {
    'noformat': 'Данный тип файлов пока не поддерживается',
    'photograph': 'Сделать фото',
    'album': 'Галерея',
    'video': 'Видео',
    'customer': 'Служба поддержки',
    'solve.icon': 'Если проблема решена, нажмите здесь',
    'appraisalPopup.title': 'Оценить поддержку',
    'appraisalPopup.solved': 'Решено',
    'appraisalPopup.unsolved': 'Не решено',
    'appraisalPopup.problem_finished': 'Пожалуйста, выберите, решена ли проблема',
    'appraisalPopup.desc': 'Был ли уже решён ваш вопрос?',
    'appraisalPopup.sub_title': 'Рейтинг услуг',
    'appraisalPopup.feedback': 'Отзывы',
    'appraisalPopup.submit': 'Отправить',
    'endPopup.title': 'Завершить сеанс общения?',
    'endPopup.sub_title': 'У вас есть еще вопросы?',
    'network': 'Текущая сеть нестабильна, проверьте настройки сети',
    'sendInThe': 'отправка'
};