import { MsgReq , MsgData , MsgRead , Unread , NewMsg , GetSeq , Inbox , Iadmit , IclearRead } from './imtypes';
import { MsgCache , MsgResend } from './msgeResend';
import { getUid } from './function';
import md5 from 'js-md5';

const platform = 1; // 平台

var IMWS:any;   // WebSocket实例

// 启动消息重发自检
sessionStorage.setItem('NeedResend','true');                    // 上一轮消息重发是否执行完成
sessionStorage.setItem('MsgResendCache',JSON.stringify([]));    // 消息重试缓存清空
setInterval(()=>{
    if(localStorage.getItem('NeedResend') === 'true'){
        MsgResend();
    };
},1000);

// Webstocket创建连接
function createWS(wsurl:string){
    IMWS = new WebSocket(wsurl);
}

// Webstocket发送消息
function socketSend(message:string){
    IMWS.send(message);
}

// Webstocket关闭连接
function socketClose(){
    IMWS.close();
}

// 发送消息
function sendMessage(data:MsgData,msg_incr:string){
    const message:MsgReq = {
        identify: 'send_msg',                                   // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: msg_incr,                                     // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
    MsgCache(msg_incr,Msg);     // 消息重发机制
}

// 创建文本消息
function createTextMessage(text:string,receiver:string,chat_type:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:MsgData = {
            sender_id: getUid(),                                // 本人UID
            receiver_id: chat_type === 1 ? receiver : '',       // 接收者ID
            group_id: chat_type === 1 ? '' : receiver,          // 群ID
            sender_platform_id: platform,                       // 平台
            chat_type: chat_type,                               // 聊天类型：1单聊 2客服群聊
            content_type: 101,                                  // 消息类型
            content: text                                       // 消息内容
        }
        resolve(message);
    })
}

// 创建图片消息
function createImageMessage(text:string,receiver:string,chat_type:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:MsgData = {
            sender_id: getUid(),                                // 本人UID
            receiver_id: chat_type === 1 ? receiver : '',       // 接收者ID
            group_id: chat_type === 1 ? '' : receiver,          // 群ID
            sender_platform_id: platform,                       // 平台
            chat_type: chat_type,                               // 聊天类型：1单聊 2客服群聊
            content_type: 102,                                  // 消息类型
            content: text                                       // 消息内容
        }
        resolve(message);
    })
}

// 创建视频消息
function createVideoMessage(text:string,receiver:string,chat_type:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:MsgData = {
            sender_id: getUid(),                                // 本人UID
            receiver_id: chat_type === 1 ? receiver : '',       // 接收者ID
            group_id: chat_type === 1 ? '' : receiver,          // 群ID
            sender_platform_id: platform,                       // 平台
            chat_type: chat_type,                               // 聊天类型：1单聊 2客服群聊
            content_type: 103,                                  // 消息类型
            content: text                                       // 消息内容
        }
        resolve(message);
    })
}

// 创建文件消息
function createFileMessage(text:string,receiver:string,chat_type:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:MsgData = {
            sender_id: getUid(),                                // 本人UID
            receiver_id: chat_type === 1 ? receiver : '',       // 接收者ID
            group_id: chat_type === 1 ? '' : receiver,          // 群ID
            sender_platform_id: platform,                       // 平台
            chat_type: chat_type,                               // 聊天类型：1单聊 2客服群聊
            content_type: 104,                                  // 消息类型
            content: text                                       // 消息内容
        }
        resolve(message);
    })
}

// 消息已读
function readMessage(data:MsgRead){
    const message:MsgReq = {
        identify: 'mark_msg_by_seq',                            // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(data.seq.toString()),      // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 创建消息已读数据体
function createReadMessageData(seq:number,chat_type:number,opposite_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:MsgRead = {
            seq:seq,                                        // SEQ排序对齐
            user_id:getUid(),                               // 本人UID
            chat_type:chat_type,                            // 聊天类型
            opposite_id:opposite_id                         // 对聊的用户ID 或 群ID
        }
        resolve(message);
    })
}

// 检测连接状态
function detectConnectionStatus(){
    const message:MsgReq = {
        identify: 'ping',                                       // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: 'ping'                                            // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 拉取未读消息
function pullUnreadMessage(data:Unread){
    const message:MsgReq = {
        identify: 'get_unread_msg_by_max_seq',                  // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(data.max_seq.toString()),  // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 创建 拉取小于客户端最大SEQ的未读消息 数据体
function createUnreadData(chat_type:number,opposite_id:string,max_seq:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:Unread = {
            user_id:getUid(),                               // 本人UID
            chat_type:chat_type,                            // 聊天类型
            opposite_id:opposite_id,                        // 对聊的用户ID 或 群ID
            max_seq:max_seq,                                // 本地最大SEQ
            size:10                                         // 拉取消息条数
        }
        resolve(message);
    })
}

// 拉取最新消息
function pullNewMessage(data:NewMsg){
    const message:MsgReq = {
        identify: 'pull_msg_by_max_seq',                        // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(data.max_seq.toString()),  // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 创建 拉取大于客户端最大SEQ的最新消息 数据体
function createNewData(chat_type:number,opposite_id:string,max_seq:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:NewMsg = {
            user_id:getUid(),                               // 本人UID
            chat_type:chat_type,                            // 聊天类型
            opposite_id:opposite_id,                        // 对聊的用户ID 或 群ID
            max_seq:max_seq,                                // 本地最大SEQ
            size:10                                         // 拉取消息条数
        }
        resolve(message);
    })
}

// 获取用户及群最大和最小SEQ
function GetMaxAndMinSeq(data:GetSeq){
    const message:MsgReq = {
        identify: 'get_max_and_min_seq',                        // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 创建 获取用户及群最大和最小SEQ 数据体
function createMaxAndMinSeq(group_id_list:any=[]){
    return new Promise((resolve:any,reject:any)=>{
        const message:GetSeq = {
            group_id_list:group_id_list,                        // 群聊ID数组
            user_id:getUid(),                                      // 本人UID
        }
        resolve(message);
    })
}

// 拉取收件箱信息
function GetInboxMessage(data:Inbox){
    const message:MsgReq = {
        identify: 'inbox_msg_list',                             // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(data.last_seq.toString()), // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 创建拉取收件箱数据体
function createInboxData(chat_type:number,opposite_id:string,last_seq:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:Inbox = {
            user_id:getUid(),                               // 本人UID
            chat_type:chat_type,                            // 聊天类型
            opposite_id:opposite_id,                        // 对聊的用户ID 或 群ID
            last_seq:last_seq,                              // 当前会话最小SEQ
            size:10                                         // 拉取消息条数
        }
        resolve(message);
    })
}

// 获取接待信息
function GetAdmitInfo(data:Iadmit){
    const message:MsgReq = {
        identify: 'seat_reception_status',                      // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 获取接待信息 数据体
function createAdmitData(group_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:Iadmit = {
            group_id:group_id
        }
        resolve(message);
    })
}

// 点击开始联系客服
function startLinkCustomer(data:Iadmit){
    const message:MsgReq = {
        identify: 'seat_reception_contact',                     // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 点击联系客服 数据体
function createLinkCustomerData(group_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:Iadmit = {
            group_id:group_id
        }
        resolve(message);
    })
}

// 接待完成后再次发送消息开始联系客服
function newStartLinkCustomer(data:Iadmit){
    const message:MsgReq = {
        identify: 'seat_reception_new',                         // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 接待完成后再次发送消息 数据体
function newCreateLinkCustomerData(group_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:Iadmit = {
            group_id:group_id
        }
        resolve(message);
    })
}

// 在线留言
function startOnlineMsg(data:Iadmit){
    const message:MsgReq = {
        identify: 'seat_reception_leave_msg',                   // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 在线留言 数据体
function createOnlineMsg(group_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:Iadmit = {
            group_id:group_id
        }
        resolve(message);
    })
}

// 同意接受会话
function EndSession(data:Iadmit){
    const message:MsgReq = {
        identify: 'seat_reception_end',                         // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 同意结束会话 数据体
function createEndSessionData(group_id:string){
    return new Promise((resolve:any,reject:any)=>{
        const message:Iadmit = {
            group_id:group_id
        }
        resolve(message);
    })
}

// 同意接受会话 + 结束接待
function EndSessionFinish(data:any){
    const message:MsgReq = {
        identify: 'seat_reception_end_and_evaluate',            // 类型：发送消息
        sender_id: getUid(),                                    // 本人UID
        product_id: Number(localStorage.getItem('productID')),  // 当前产品ID
        msg_incr: createRandomString(''),                       // 消息标识随机串
        data: JSON.stringify(data)                              // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 同意结束会话 + 结束接待 数据体
function createEndSessionFinishData(reception_id:number,score:number,content:string,problem_finished:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:any = {
            reception_id,
            score,
            content,
            problem_finished
        }
        resolve(message);
    })
}

// 标记所有消息已读
function clearMessageRead(data:IclearRead){
    const message:MsgReq = {
        identify: 'clear_conversation_unread',                          // 类型：标记所有消息已读
        sender_id: getUid(),                                            // 本人UID
        product_id: Number(localStorage.getItem('productID')),          // 当前产品ID
        msg_incr: createRandomString(data.conversation_id.toString()),  // 消息标识随机串
        data: JSON.stringify(data)                                      // 消息JSON字符串
    }
    const Msg = JSON.stringify(message);
    socketSend(Msg);
}

// 标记所有消息已读 数据体
function createMessageReadData(conversation_id:number){
    return new Promise((resolve:any,reject:any)=>{
        const message:IclearRead = {
            conversation_id:conversation_id
        }
        resolve(message);
    })
}


// 随机串生成
function createRandomString(content:string) {
    const random = parseInt(Math.random().toString().substring(2), 10).toString().substring(0, 8);
    const now = Date.now();
    const code = parseInt(random + now.toString() + Math.random().toString() + content, 10).toString(36).toUpperCase();
    const Rstr = code.slice(0,-3) + getUid();
    return md5(Rstr);
}



export {
    createRandomString,
    IMWS,
    createWS,
    socketSend,
    socketClose,
    sendMessage,
    createTextMessage,
    createImageMessage,
    createVideoMessage,
    createFileMessage,
    readMessage,
    createReadMessageData,
    detectConnectionStatus,
    pullUnreadMessage,
    createUnreadData,
    pullNewMessage,
    createNewData,
    GetMaxAndMinSeq,
    createMaxAndMinSeq,
    GetInboxMessage,
    createInboxData,
    GetAdmitInfo,
    createAdmitData,
    createLinkCustomerData,
    startLinkCustomer,
    newStartLinkCustomer,
    newCreateLinkCustomerData,
    createOnlineMsg,
    startOnlineMsg,
    createEndSessionData,
    EndSession,
    createMessageReadData,
    clearMessageRead,
    EndSessionFinish,
    createEndSessionFinishData
}