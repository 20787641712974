export default {
    'noformat': 'Tạm thời không hỗ trợ định dạng tập tin này',
    'photograph': 'Chụp ảnh',
    'album': 'Album',
    'video': 'Video',
    'customer': 'Dịch vụ khách hàng',
    'solve.icon': 'Nếu vấn đề đã được giải quyết, vui lòng bấm vào đây',
    'appraisalPopup.title': 'Đánh giá dịch vụ khách hàng',
    'appraisalPopup.solved': 'Đã giải quyết',
    'appraisalPopup.unsolved': 'Chưa giải quyết',
    'appraisalPopup.problem_finished': 'Vui lòng chọn xem vấn đề đã được giải quyết hay chưa',
    'appraisalPopup.desc': 'Vấn đề của bạn đã được giải quyết chưa?',
    'appraisalPopup.sub_title': 'Điểm đánh giá dịch vụ',
    'appraisalPopup.feedback': 'Phản hồi ý kiến',
    'appraisalPopup.submit': 'Gửi',
    'endPopup.title': 'Bạn có muốn kết thúc phiên trò chuyện không?',
    'endPopup.sub_title': 'Bạn còn câu hỏi nào không?',
    'network': 'Mạng không ổn định hiện tại, vui lòng kiểm tra cài đặt mạng của bạn',
    'sendInThe':'gửi'
};