export default {
    'noformat': 'This file type is not supported temporarily',
    'photograph': 'photograph',
    'album': 'album',
    'video': 'video',
    'customer': 'Customer service',
    'solve.icon': 'If the problem has been resolved, please click here',
    'appraisalPopup.title': 'Evaluation',
    'appraisalPopup.solved': 'Resolved',
    'appraisalPopup.unsolved': 'Unresolved',
    'appraisalPopup.problem_finished': 'Please select if the problem has been solved',
    'appraisalPopup.desc': 'Has your problem been solved already?',
    'appraisalPopup.sub_title': 'Service score',
    'appraisalPopup.feedback': 'Feedback',
    'appraisalPopup.submit': 'Submit',
    'endPopup.title': 'Whether to end the session',
    'endPopup.sub_title': 'Do you have any questions?',
    'network': 'The current network is not stable',
    'sendInThe': 'Send in'
};