export default {
    'noformat': 'Jenis file ini belum didukung',
    'photograph': 'Ambil foto',
    'album': 'Album',
    'video': 'Video',
    'customer': 'Layanan Pelanggan',
    'solve.icon': 'Jika masalah sudah diselesaikan,silakan klik di sini',
    'appraisalPopup.title': 'Penilaian Layanan Pelanggan',
    'appraisalPopup.solved': 'Telah diselesaikan',
    'appraisalPopup.unsolved': 'Belum diselesaikan',
    'appraisalPopup.problem_finished': 'Silakan pilih apakah masalah telah diselesaikan',
    'appraisalPopup.desc': 'Apakah masalah Anda sudah terpecahkan?',
    'appraisalPopup.sub_title': 'Penilaian Layanan',
    'appraisalPopup.feedback': 'Saran dan Masukan',
    'appraisalPopup.submit': 'Kirim',
    'endPopup.title': 'Apakah Anda ingin mengakhiri sesi chat ini?',
    'endPopup.sub_title': 'Apakah ada pertanyaan lain yang bisa saya bantu?',
    'network': 'Jaringan tidak stabil, harap periksa pengaturan jaringan Anda',
    'sendInThe': 'Mengirim...'
};